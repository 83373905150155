import './App.css';
import React from 'react';
import PlanningPoker from './client/planningpoker';

function App() {
  return (
    <PlanningPoker/>
  );
}

export default App;
