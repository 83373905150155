import React, { Component }  from 'react';
import Login from './login';
import Poker from './poker';

export default class PlanningPoker extends Component {

    constructor() {
        super();
        this.state = {

        }
        this.handleHashChanged = this.handleHashChanged.bind(this);
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChanged);
        this.handleHashChanged();
    }
    
    componentWillUnmount() {
        window.removeEventListener("hashchange", this.handleHashChanged);
    }

    handleHashChanged() {
        var roomHash = window.location.hash.substr(1);
        if (!roomHash) {
            fetch('/createroom').then(response => response.json()).then(room => {
                window.location.href = window.location.href + "#" + room;
            });
        } else {
            this.setState({ room: roomHash }, ()=>{
                console.log(this.state.room);
            });
        }
    }

    render() {
        if (this.state.room && this.state.playerName) {
            return <Poker room={this.state.room} playerName={this.state.playerName} />
        }

        return <Login onLogin={(name) => { this.setState({ playerName: name }) }} />
    }
}