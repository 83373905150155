import React, { Component }  from 'react';

export default class Login extends Component {

    constructor() {
        super();
        this.state = {
            playerName: ""
        }
    }

    render() {
        return (<div id="login" className="content">
            <form onSubmit={(e) => { e.preventDefault(); }}>
                <input placeholder="Name" type="text" value={this.state.playerName} onChange={(e) => this.setState({ playerName: e.target.value })}></input>
                <input type="submit" value="Login" onClick={() => { this.props.onLogin(this.state.playerName) }} />
            </form>
        </div>)
    }
}