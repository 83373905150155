import React, { Component, Fragment } from 'react';

import io from 'socket.io-client';

export default class Poker extends Component {

    constructor() {
        super();
        this.cards = [1, 2, 3, 5, 8, 13, 20, 40, 100];
        this.state = {
            isAdmin: false,
            storyInput: "",
            story: "",
            answer: -1,
            firstTimePokered: true,
        }
        this.handleCardClicked = this.handleCardClicked.bind(this);
        this.startPoker = this.startPoker.bind(this);
    }

    componentDidMount() {
        this.socket = io("/", {
            query: {
                room: this.props.room,
                name: this.props.playerName
            }
        });
        this.socket.on('updatePlayers', (players) => {
            this.setState({ players });
        })
        this.socket.on('setAdmin', (isAdmin) => {
            this.setState({ isAdmin });
        })
        this.socket.on('setStory', (data) => {
            this.setState({ story: data.story, isRepoker: data.isRepoker, previousAnswer: this.state.answer, answer: -1, results: undefined });
        })
        this.socket.on('setResults', (results) => {
            this.setState({ results });
        })
        this.socket.on('setPlayerState', (playerStates) => {
            this.setState({ playerStates: playerStates });
        })
    }

    componentWillUnmount() {
        this.socket.close();
    }

    moveAdmin(adminName) {
        if (this.state.isAdmin) {
            if (window.confirm(`Want to make ${adminName} the admin?`)) {
                this.socket.emit('moveAdmin', adminName);
            }
        }
    }

    render() {
        var adminContent;
        var storyContent;
        var cardsContent;
        var resultsContent;
        var playerStateContent;

        if (this.state.isAdmin) {
            adminContent = (
                <div id="admin-form">
                    <form onSubmit={(e) => { e.preventDefault(); }}>
                        <input placeholder="Enter Story Here" type="text" onChange={(e) => { this.setState({ firstTimePokered: true, storyInput: e.target.value }) }} value={this.state.storyInput}></input>
                        <input type="submit" onClick={this.startPoker} value={this.state.firstTimePokered ? "Poker" : "Re-poker"}></input>
                    </form>
                </div>
            )
        }

        if (this.state.story) {
            storyContent = (<h2 id="story">“{this.state.story}”</h2>);
        }

        if (this.state.playerStates) {
            playerStateContent = (<div id="player-states">
                <ul>
                    {this.state.playerStates.players.map((p) => {
                        return (<li onClick={() => { this.moveAdmin(p.name) }} className={p.ready ? "ready" : ""} key={p.name}>{p.isAdmin ? "★ " : ""}{p.name} {p.ready ? "✓" : ""}</li>)
                    })}
                </ul>
            </div>)
        }


        if (this.state.results) {
            console.log(this.state.results);
            resultsContent = (<div id="results">
                {this.state.results.answers.map(r => {
                    var className = "result";
                    var delta = 0;
                    if (r.previousAnswer > 0) {
                        if (r.answer - r.previousAnswer > 0) {
                            delta = 1;
                        }
                        if (r.answer - r.previousAnswer < 0) {
                            delta = -1;
                        }
                    }
                    className += r.name === this.state.results.highestPlayer ? " highest" : "";
                    className += r.name === this.state.results.lowestPlayer ? " lowest" : "";
                    return (
                        <div className={className} key={r.name}>
                            <div className="name">{r.name}:</div>
                            <div className="answer">
                                {delta === 1 ? (<span className="delta">↑</span>) : ""}
                                {delta === -1 ? (<span className="delta">↓</span>) : ""}
                                {r.answer}
                            </div>
                        </div>
                    )
                })}
            </div>)
        }
        else if (this.state.story) {
            cardsContent = (
                <div id="cards">
                    {this.cards.map(card => {
                        var isPrevious = card === this.state.previousAnswer && this.state.isRepoker;
                        var className = "card" + (this.state.answer === card ? " picked" : "") + (isPrevious ? " previous-answer" : "");
                        return (
                            <div className={className} key={card} onClick={() => { this.handleCardClicked(card) }}>{card}</div>
                        )
                    })}
                </div>);
        }

        var allSameClassName = "";
        if (this.state.results && this.state.results.allSame && !this.state.isRepoker) {
            allSameClassName = "all-same";
        }

        return (
            <Fragment>
                <div id="left-content" className="content">
                    {playerStateContent}
                </div>
                <div id="right-content" className="content">
                    <div id="background" className={allSameClassName}></div>
                    {adminContent}
                    {storyContent}
                    {cardsContent}
                    {resultsContent}
                </div>
            </Fragment>)
    }

    startPoker() {
        var isRepoker = !this.state.firstTimePokered;
        this.setState({ firstTimePokered: false });
        this.socket.emit('setStory', this.state.storyInput, isRepoker);
    }

    handleCardClicked(value) {
        this.setState({ answer: value });
        this.socket.emit('setPoints', value);
    }
}